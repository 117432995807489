import Api from '@/services/Index';

const prepExamScore = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/exam/scores/import', formData);
}

export default {
    prepExamScore,
}
