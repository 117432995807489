// Plugins
import i18n from '@/plugins/i18n'

export default function() {
    let reportTypes = [];

    reportTypes.push({
        value: "prep-bulk-grade",
        text: i18n.t("preparatory_bulk_grade_entry"),
        permission: "prepexamscore_importexamscorefromexcel",
    });

    return reportTypes;

}


